import idHelper from "../../02-tools/id-helper/idHelper";
import Keyboard from "../../02-tools/keyboard/keyboard";

export default class Accordion {
    constructor(element) {
        const headers = element.querySelectorAll(".accordion__heading");

        const anchors = document.getElementsByTagName("a");

        const anchorList = [];
        for (const anchor of anchors) {
            const href = anchor.href;

            if (
                href.includes("#") &&
                href.startsWith(document.location.origin)
            ) {
                const id = href.split("#")[1];
                id && anchorList.push({ element: anchor, id });
            }
        }

        if (headers) {
            let scrollRestoration = "auto";

            for (const header of headers) {
                const button = header.querySelector(".accordion__button"),
                    region = header.nextElementSibling,
                    hash = decodeURI(location.hash).substring(1),
                    hashMatch =
                        hash &&
                        (hash.includes(button.id) || button.id.includes(hash));

                let id = hashMatch ? hash : button.id;

                this.addIds(region, header.id);

                button.addEventListener("click", (e) => {
                    e.preventDefault();

                    header.classList.toggle("accordion__heading--expanded");
                    region.classList.toggle("accordion__region--expanded");

                    if (
                        header.classList.contains(
                            "accordion__heading--expanded",
                        )
                    ) {
                        history.replaceState(
                            {},
                            null,
                            `#${encodeURI(header.id)}`,
                        );
                        button.setAttribute("aria-expanded", true);
                        region.setAttribute("aria-hidden", false);
                    } else {
                        id = button.id;
                        history.replaceState({}, null, " ");
                        button.setAttribute("aria-expanded", false);
                        region.setAttribute("aria-hidden", true);
                    }

                    button.focus();
                });

                button.addEventListener("keyup", (e) => {
                    const evt = e || window.event;
                    if (
                        evt.which === Keyboard.right ||
                        evt.which === Keyboard.space
                    ) {
                        e.preventDefault();
                        button.click();
                    }
                });

                anchorList.forEach((anchor) => {
                    if (anchor.id.includes(header.id)) {
                        anchor.element.addEventListener("click", (e) => {
                            e.preventDefault();

                            location.hash = encodeURI(anchor.id);

                            header.classList.add(
                                "accordion__heading--expanded",
                            );
                            region.classList.add("accordion__region--expanded");

                            button.setAttribute("aria-expanded", true);
                            region.setAttribute("aria-hidden", false);
                        });
                    }
                });

                if (hashMatch) {
                    scrollRestoration = "manual";

                    button.click();
                    (document.getElementById(id) || button).scrollIntoView();

                    const header = document.querySelector(".polno-header"),
                        rect1 = header.getBoundingClientRect(),
                        rect2 = button.getBoundingClientRect();

                    const overlap = !(
                        rect1.right < rect2.left ||
                        rect1.left > rect2.right ||
                        rect1.bottom < rect2.top ||
                        rect1.top > rect2.bottom
                    );

                    if (overlap) {
                        scrollTo(0, scrollY - header.offsetHeight);
                    }
                }
            }

            history.scrollRestoration = scrollRestoration;
        }
    }

    addIds(content, prefix) {
        const h2s = content.getElementsByTagName("h2");
        const h3s = content.getElementsByTagName("h3");
        const h4s = content.getElementsByTagName("h4");
        const h5s = content.getElementsByTagName("h5");
        const h6s = content.getElementsByTagName("h6");

        this.loopThrough(h2s, prefix);
        this.loopThrough(h3s, prefix);
        this.loopThrough(h4s, prefix);
        this.loopThrough(h5s, prefix);
        this.loopThrough(h6s, prefix);
    }

    loopThrough(headings, prefix) {
        for (const heading of headings) {
            heading.setAttribute(
                "id",
                idHelper.createId(
                    prefix.trim() + " " + heading.textContent.trim(),
                ),
            );
        }
    }
}
