import GlobalText from '../../02-tools/global-text/global-text';
import idHelper from '../../02-tools/id-helper/idHelper';

export default class EpiserverForms {
  constructor(element) {
    const validationButtons = element.querySelectorAll('.btnNext, .FormSubmitButton');
    const resetButtons = element.querySelectorAll('.btnPrev, .FormResetButton');
    const formElements = element.querySelectorAll('.Form__Element');
    const formMainBody = element.querySelector('.Form__MainBody');

    GlobalText.get().then((data) => {
      let notificationHeading = document.createElement('h2');
      notificationHeading.classList.add('notification__heading');
      let notificationHeadingText = document.createTextNode(data.episerverforms.errorsheading);
      notificationHeading.appendChild(notificationHeadingText);
      notificationHeading.setAttribute('id', idHelper.createId(notificationHeading.innerHTML));

      let notificationList = document.createElement('ul');
      notificationList.classList.add('notification__list');
      notificationList.setAttribute('aria-live', 'assertive');
      notificationList.setAttribute('aria-relevant', 'additions removals');

      let notification = document.createElement('div');
      notification.classList.add('episerver-forms__notification', 'notification', 'notification--error', 'notification--hidden');
      notification.setAttribute('role', 'alert');
      notification.appendChild(notificationHeading);
      notification.appendChild(notificationList);

      formMainBody.insertBefore(notification, formMainBody.childNodes[0]);

      this.notification = element.querySelector('.episerver-forms__notification');
      this.notificationList = this.notification.querySelector('.notification__list');
    }).catch((error) => {
      console.log('error', error);
    });

    this.errorCounter = 0;

    // Reset form on pageLoad
    window.addEventListener('pageshow', function (event) {
      element.reset();
    });

    for (const button of validationButtons) {
      button.addEventListener('click', (e) => {
        setTimeout(() => {
          this.checkValidation(formElements)
        }, 100);
      });
    }

    for (const button of resetButtons) {
      button.addEventListener('click', (e) => {
        this.resetValidation();
      });
    }
  }

  resetValidation() {
    if (this.notification && this.notificationList) {
      this.notification.classList.add('notification--hidden');
      this.notificationList.innerHTML = '';
      this.errorCounter = 0;
    }
  }

  checkValidation(formElements) {
    if (this.notification && this.notificationList) {
      this.notificationList.innerHTML = '';
      this.errorCounter = 0;

      for (const element of formElements) {
        if (element.classList.contains('ValidationFail')) {
          this.errorCounter++;
          this.addItemToSummary(element.querySelector('.Form__Element__Caption').innerHTML);
        }
      }

      if (this.errorCounter > 0) {
        this.notification.classList.remove('notification--hidden');
        window.scrollTo(0, this.notification.offsetTop - document.querySelector('.polno-header').offsetHeight - 24);
      } else if (this.errorCounter === 0) {
        this.notification.classList.add('notification--hidden');
      }
    }
  }

  addItemToSummary(item) {
    let li = document.createElement('li');
    li.classList.add('notification__list-item');
    let liText = document.createTextNode(item);
    li.appendChild(liText);

    this.notificationList.appendChild(li);
  }
}
