import React from 'react';
import Params from '../../02-tools/params/params';
import SCROLLTO from '../../02-tools/scroll-to/scroll-to';
import Insight from '../../02-tools/insight/insight';

/*type Props = {
  Translations: Array<Object>;
  PaginationFilterItem: Array<Object>;
};*/

export default class FilterPaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Params: Params.getParams(),
    };
  }

  setPaging(e, page) {
    e.preventDefault();
    const filterResults = document.querySelector('.filter__results');
    const pageHeader = document.querySelector('.polno-header');

    this.state.Params = Params.getParams();
    this.state.Params[this.props.PaginationFilterItem.Parameter] =
      Params.addParam(page.toString());

    this.props.updateParams(this.state.Params);

    if (typeof window === 'object' && filterResults) {
      window.scrollTo(0, filterResults.offsetTop - pageHeader.offsetHeight);
    }

    const current = document.querySelector('.paging__paging-item--current');
    current.setAttribute('tabIndex', '0');
    current.focus();
    current.removeAttribute('tabIndex');
    Insight.track('ga', {
      isEvent: false,
      data: location.pathname + location.search,
    });
    Insight.track('matomo', {
      isEvent: false,
      data: location.pathname + location.search,
      title: document.title,
    });
  }

  render() {
    const p = this.props;
    const pagingItems = 5;
    const hasNext =
      p.PaginationFilterItem.ActivePage < p.PaginationFilterItem.TotalPages
        ? true
        : false;
    const hasPrev = p.PaginationFilterItem.ActivePage > 1 ? true : false;

    let pages = [];
    let currentParams = Params.getParams();

    for (
      let i = p.PaginationFilterItem.FirstPage - 1;
      i < p.PaginationFilterItem.LastPage;
      i++
    ) {
      pages.push(i + 1);
    }

    if (
      p.PaginationFilterItem.TotalPages <= 1 &&
      Params.getParams()[this.props.PaginationFilterItem.Parameter]
    ) {
      let paramName = this.props.PaginationFilterItem.Parameter;
      let paramValue =
        this.state.Params[this.props.PaginationFilterItem.Parameter];
      this.props.removeParamAndUpdateQueryString(paramName, paramValue);
    }

    return (
      <nav className='xsmall-12 medium-10 xlarge-8 column'>
        <ol
          className={
            p.PaginationFilterItem.TotalPages <= 1
              ? 'paging paging--hidden list--reset-style'
              : 'paging list--reset-style'
          }
        >
          <li
            className={
              hasPrev
                ? 'paging__paging-item paging__paging-item--prev-item paging__paging-item--visible'
                : 'paging__paging-item paging__paging-item--prev-item'
            }
          >
            <a
              className='paging__paging-item-link paging__paging-item-link--prev-link link--no-underline'
              href={
                '?' +
                p.PaginationFilterItem.Parameter +
                '=' +
                (p.PaginationFilterItem.ActivePage - 1)
              }
              onClick={(e) =>
                this.setPaging(e, p.PaginationFilterItem.ActivePage - 1)
              }
              aria-label={p.Translations.Previous + ' ' + p.Translations.Page}
            >
              {p.Translations.Previous}
            </a>
          </li>

          {p.PaginationFilterItem.ActivePage > 3 &&
          p.PaginationFilterItem.TotalPages > pagingItems ? (
            <li className='paging__paging-item'>
              <a
                className='paging__paging-item-link link--no-underline'
                href={'?' + p.PaginationFilterItem.Parameter + '=1'}
                onClick={(e) => this.setPaging(e, 1)}
                aria-label={p.Translations.JumpFirst}
              >
                1
              </a>
            </li>
          ) : null}
          {p.PaginationFilterItem.ActivePage > 4 &&
          p.PaginationFilterItem.TotalPages > pagingItems ? (
            <li className='paging__paging-item paging__paging-item--leap'></li>
          ) : null}

          {pages && pages.length
            ? pages.map((page, i) => {
                const liClass =
                  page === p.PaginationFilterItem.ActivePage
                    ? 'paging__paging-item paging__paging-item--current'
                    : 'paging__paging-item';
                const innerHtml =
                  page === p.PaginationFilterItem.ActivePage ? (
                    page
                  ) : (
                    <a
                      className='paging__paging-item-link link--no-underline'
                      href={'?' + p.PaginationFilterItem.Parameter + '=' + page}
                      onClick={(e) => this.setPaging(e, page)}
                      aria-label={p.Translations.GoToPage + ' ' + page}
                    >
                      {page}
                    </a>
                  );

                return (
                  <li className={liClass} key={i}>
                    {innerHtml}
                  </li>
                );
              })
            : null}

          {p.PaginationFilterItem.ActivePage + 3 <
            p.PaginationFilterItem.TotalPages &&
          p.PaginationFilterItem.TotalPages > pagingItems ? (
            <li className='paging__paging-item paging__paging-item--leap'></li>
          ) : null}
          {p.PaginationFilterItem.ActivePage + 2 <
            p.PaginationFilterItem.TotalPages &&
          p.PaginationFilterItem.TotalPages > pagingItems ? (
            <li className='paging__paging-item'>
              <a
                className='paging__paging-item-link link--no-underline'
                href={
                  '?' +
                  p.PaginationFilterItem.Parameter +
                  '=' +
                  p.PaginationFilterItem.TotalPages
                }
                onClick={(e) =>
                  this.setPaging(e, p.PaginationFilterItem.TotalPages)
                }
                aria-label={p.Translations.JumpLast}
              >
                {p.PaginationFilterItem.TotalPages}
              </a>
            </li>
          ) : null}

          <li
            className={
              hasNext
                ? 'paging__paging-item paging__paging-item--next-item paging__paging-item--visible'
                : 'paging__paging-item paging__paging-item--next-item'
            }
          >
            <a
              className='paging__paging-item-link paging__paging-item-link--next-link link--no-underline'
              href={
                '?' +
                p.PaginationFilterItem.Parameter +
                '=' +
                (p.PaginationFilterItem.ActivePage + 1)
              }
              onClick={(e) =>
                this.setPaging(e, p.PaginationFilterItem.ActivePage + 1)
              }
              aria-label={p.Translations.Next + ' ' + p.Translations.Page}
            >
              {p.Translations.Next}
            </a>
          </li>
        </ol>
      </nav>
    );
  }
}
